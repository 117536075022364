/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "styles/variables";
@import "styles/forms";
@import "styles/decade_popover";
@import "styles/fonts.css";


html {
  height: -webkit-fill-available;
}

body {
  background-color: var(--bg-main-color);
  font-family: 'dapifer';
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

h1 {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

ng-select {
  box-shadow: 0 2px 3px lightgrey;
  border-radius: 7px;

  &.ng-select-disabled {
    .ng-select-container {
      cursor: not-allowed;

      > * {
        pointer-events: none;
      }
    }
  }
}

.ng-value-container {
  color: var(--fourth-font-color);
  font-size: 12px;
  //padding-left: 0 !important;

  .ng-input {
    top: 10px !important;
    padding-left: 0 !important;

    input {
      margin-bottom: 0 !important;
      padding-left: 0 !important;
    }
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: rgb(117, 117, 117) !important;
  }
}

ng-select {
  &.ng-select-disabled > .ng-select-container {
    background-color: #eaeaea !important;
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    height: 40px;
    border: 1px solid #C9C9C9;
    border-radius: 7px;
    padding-left: 15px;

    input {
      height: initial;
    }

    .ng-placeholder {
      color: rgb(117, 117, 117);
      //font-family: Montserrat;
      font-weight: light;
      top: 0;
    }

    .ng-input {
      left: 15px !important;
      //top: 5px !important;
    }
  }

  .ng-option {
    font-size: 12px;
  }
}

.ng-select.ng-select-multiple {
  .ng-select-container {
    .ng-placeholder {
      top: 10px !important;
    }
  }
}

.custom-select-control {
  .ng-value-container {
    padding-left: 0 !important;
  }

  .ng-option {
    line-height: 1em;
    display: flex !important;

    .custom-select-item-name {
      font-size: 14px;
      display: block;
      height: 14px;
    }

    .select-custom-checkbox {
      position: relative;
      margin-right: 5px;

      input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;

        font: inherit;
        color: var(--accent-color);
        width: 1em;
        height: 1em;
        border: 1px solid #E1E1E1;
        border-radius: 5px;
        box-sizing: border-box;
        position: relative;

        &:checked::before {
          font-family: 'Font Awesome 5 Free';
          font-weight: 700;
          content: '\f00c';
          font-size: 0.55em;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  //.ng-arrow-wrapper .ng-arrow {
  //  border-color: var(--accent-color) transparent transparent !important;
  //}
  //
  //&.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  //  border-color: transparent transparent var(--accent-color) !important;
  //}

  .ng-option-selected {
    background-color: transparent !important;
  }

  .ng-select-container {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    height: 40px;
    border: 1px solid #C9C9C9;
    border-radius: 7px;
    padding-left: 15px;
  }

  .ng-dropdown-panel {
    &.ng-select-top {
      border: 1px solid lightgray !important;
      border-radius: 8px 8px 0 0;
      border-bottom: none !important;
    }

    &.ng-select-bottom {
      border: 1px solid lightgray !important;
      border-radius: 0 0 8px 8px;
      border-top: none !important;
    }
  }
}

google-map {
  .view-link {
    display: none !important;
  }

  .gm-style-cc {
    pointer-events: none;
  }

  .gm-style a[href^="https://maps.google.com/maps"] {
    pointer-events: none;
  }
}
