input[type="button"], button.custom-button {
  background-color: var(--accent-color);
  color: var(--accent-text-color);
  font-size: 0.8em;
  min-width: 180px;
  height: 45px;
  border-radius: 50px;
  padding: 0 20px;
  outline: none;
  border: 0;

  //@media screen and (max-width: 568px) {
  //  font-size: 1em;
  //  height: 35px;
  //}
  //
  &[disabled] {
    filter: brightness(2) grayscale(0.5);
    //background-color: gray;
    //color: var(--accent-text-color);
    cursor: not-allowed;
  }

  //
  //&.danger {
  //  background-color: $danger;
  //}

}

input[type="button"], button.border-button {
  background-color: white;
  border: 1px solid var(--secondary-text-color);
  box-shadow: 0 2px 3px lightgray;
  outline: none;
  width: 100%;
  //color: $gray-4;
  //margin-bottom: 10px;
  font-size: 12px;
  height: 40px;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="button"], button.raw-button {
  background-color: transparent;
  font-size: 0.8em;
  //min-width: 180px;
  height: 45px;
  padding: 0 20px;
  outline: none;
  border: 0;
}

input[type="button"], button.accent-dark-button {
  background-color: var(--accent-dark-color);
  color: white;
  font-size: 0.8em;
  min-width: 180px;
  height: 45px;
  border-radius: 50px;
  padding: 0 20px;
  outline: none;
  border: 0;
}

textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--first-color);
  background-color: #F9F9F9;
  resize: vertical;
  min-height: 200px;
  padding: 10px 15px;
  outline: none;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    border-bottom: 2px solid white;
    //-webkit-text-fill-color: $gray-4;
    -webkit-box-shadow: 0 0 0px 1000px #f0f0f0 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder {
    //color: $text-color-3;
    //font-family: Montserrat;
    font-weight: light;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &[type='email'],
  &[type='text'],
  &[type='url'],
  &[type='password'],
  &[type='number'] {
    background-color: white;
    border: 1px solid var(--secondary-text-color);
    box-shadow: 0 2px 3px lightgray;
    outline: none;
    width: 100%;
    //color: $gray-4;
    //margin-bottom: 10px;
    font-size: 12px;
    height: 40px;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;


    // ng-select fix
    &[disabled]:not([role='combobox']) {
      background-color: #eaeaea !important;
      //background-color: $gray-1;
    }
  }

  &[type='checkbox'],
  &[type='radio'] {
    margin-right: 5px;
  }

  &[type=radio]:checked ~ .check::before {
    background: #0DFF92 !important;
  }

  &[type='text'] {
    &.custom-search {
      margin-right: 25px;
    }
  }
}

label.custom-label {
  font-size: 1em;
  color: gray;
  margin-bottom: 5px;

  //@media screen and (max-width: 568px) {
  //  font-size: 0.9em;
  //  margin-bottom: 0;
  //}
}


.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: gray;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid var(--secondary-text-color);
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--accent-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//.form-error {
//  color: var(--danger-color);
//  font-size: 10px;
//  position: absolute;
//  left: 15px;
//  top: 65px;
//}

val-errors {
  color: var(--danger-color);
  font-size: 12px;
  //margin-top: -8px;
}
