:root {
  --first-color: #DDD9D8;
  --accent-color: #C83A27;
  --accent-dark-color: #543A3B;
  --secondary-text-color: lightgray;
  --danger-color: var(--accent-color);

  --accent-text-color: white;
  --bg-main-color: var(--first-color);
  --point-height: 20px;

  $test: 20px;
}

.accent-text-color {
  color: var(--accent-color);
}

a.accent-text-color {
  color: var(--accent-color);
  text-decoration: none;

  &::hover {
    color: var(--accent-color);
  }
}
